import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;

import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
	// color: 'rgb(143, 255, 199)',
	color: '#ffb000',
	failedColor: '#6e0000',
	transition: {speed: '1.5s', opacity: '0.6s', termination: 400},
	height: '2px',
});

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/style.scss'

Vue.use(BootstrapVue);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import VueResource from 'vue-resource'
Vue.use(VueResource);

import OvReq from './plugins/ov-req'
Vue.use(OvReq);

import OvNotify from './plugins/ov-notify'
Vue.use(OvNotify);

import OvData from './plugins/ov-data'
Vue.use(OvData);


import vueDebounce from 'vue-debounce'

Vue.use(vueDebounce, {
	defaultTime: '500ms',
	listenTo: 'oninput'
});
Vue.filter('toCurrency', function (value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	return formatter.format(value);
});

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, {moment});

import VModal from 'vue-js-modal'
Vue.use(VModal, {
	dynamic: true,
	injectModalsContainer: true,
	height: '90%'
});

// sort-column component
import SortColumn from './components/SortColumn';
Vue.component('ov-th', SortColumn);

// entity component
// import Entity from './components/Entity';
// Vue.component('entity', Entity);

// paginate component
import Paginate from './components/Paginate';
Vue.component('paginate', Paginate);
// paginate component
import OvModal from './components/OvModal';
Vue.component('ov-modal', OvModal);


import QuickEdit from './components/QuickEdit';
Vue.component('quick-edit', QuickEdit);

import QuickEditSelect from './components/QuickEditSelect';
Vue.component('quick-edit-select', QuickEditSelect);
// advertiser-feed-modal component
// import SelectAdvertiserFeedModal from './components/SelectAdvertiserFeedModal';
// Vue.component('select-advertiser-feed-modal', SelectAdvertiserFeedModal);



// loader plugin
import Loader from './plugins/loader';
Vue.component('loading', Loader);


import DateRangePicker from 'vue2-daterange-picker'
Vue.component('date-range-picker', DateRangePicker);
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import './plugins/date-format';

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.use(datePicker);

// datepicker component
import OvDateTimePicker from './components/OvDateTimePicker';
Vue.component('ov-date-time-picker', OvDateTimePicker);


import VueTimepicker from 'vue2-timepicker';
Vue.component('vue-timepicker', VueTimepicker);
// Vue.use(VueTimepicker);
import 'vue2-timepicker/dist/VueTimepicker.css';

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton);

// import vSelect from 'vue-select';
// Vue.component('v-select', vSelect);
// import 'vue-select/dist/vue-select.css';

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect, {
	deselectLabel : 'x',
	selectLabel: 'v'
});
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.http.options.root = process.env.VUE_APP_API_BASE_URL + 'api/partners';
Vue.envName = process.env.VUE_APP_ENV_NAME;


async function init(){
	let initData = {
		user: null,
		token: null
	};
	let user = localStorage.getItem('user');
	let token = localStorage.getItem('authToken');
	if (user) {
		try {
			user = JSON.parse(user);
		} catch (e) {
			user = null;
		}
	}

	if (token) {
		try {
			let resp = await Vue.http.get('user/getCurrent', {
				headers: {
					Authorization: 'Bearer ' + token
				}
			});
			initData.user = resp.body.data.user;
			initData.token = token;
			Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
		} catch (e) {
			user = null;
			token = null;
		}
	}

	let appStore = store(initData);
	router.beforeEach((to, from, next) => {
		console.log(appStore.state.user);
		appStore.dispatch('closeSidebar');
		if (to.name === 'login') {
			if (appStore.state.user){
				next({
					name: 'offer-list'
				});
				return;
			}
		} else {
			if (!appStore.state.user){
				next({
					name: 'login'
				});
				return;
			}
		}
		next();
	});

	new Vue({
		router,
		store: appStore,
		render: h => h(App)
	}).$mount('#app');
}

init();
