import Vue from 'vue'
import Router from 'vue-router'
import OfferList from './views/offers/List'
import Layout from './components/Layout';

import Login from './views/Login';

Vue.use(Router);

const withPrefix = (prefix, routes) =>
	routes.map((route) => {
		route.path = prefix + route.path;
		return route;
	});


let router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'login',
			component: Login
		},
		{
			path: '/app',
			name: 'app',
			component: Layout,
			children: [
				{
					path: '',
					redirect: 'offers'
				},
				// {
				// 	path: 'dashboard',
				// 	name: 'dashboard',
				// 	component: Dashboard,
				// },
				{
					path: 'publisher-payments',
					name: 'publisher-payments',
					component: () => import('./views/PublisherPayments.vue'),
				},
				{
					path: 'settings',
					name: 'settings',
					component: () => import('./views/Settings.vue')
				},
				{
					path: 'api',
					name: 'api',
					component: () => import('./views/API.vue')
				},
				...withPrefix('offers', [
					{
						name: 'offer-list', path: '',
						component: OfferList
					},
					{
						name: 'offer-view', path: '/view/:id',
						component: () => import('./views/offers/View.vue')
					}
				]),
				...withPrefix('reports', [
					{
						name: 'statistics', path: '/statistics',
						component: () => import('./views/reports/Statistics')
					},
					{
						name: 'conversions', path: '/conversions',
						component: () => import('./views/reports/Conversions')
					},
					{
						name: 'events', path: '/events',
						component: () => import('./views/reports/Events')
					},
					{
						name: 'rejected', path: '/rejected',
						component: () => import('./views/reports/Rejected')
					}
				]),
			]
		},
		// {
		// 	path: '/about',
		// 	name: 'about',
		// 	meta: {layout: 'default'},
		// 	// route level code-splitting
		// 	// this generates a separate chunk (about.[hash].js) for this route
		// 	// which is lazy-loaded when the route is visited.
		// 	component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	]
});


export default router;
