<template lang="pug">
	.side-menu
		ul.menu(v-if="user")
			li(v-for="item in menuItems", :class="{'expanded active':item===activeMenu}", v-bind:key="item.name", v-if="!item.permission || user.permissions[item.permission]")
				router-link(v-if="item.link", :to="item.link")
					i.la(:class="'la-'+item.icon")
					| {{ item.title }}
				a(v-else, href="javascript:void(0)", @click="toggleMenu(item)")
					i.la(:class="'la-'+item.icon")
					| {{ item.title }}
					span.toggle-arrow.la.la-angle-up(v-if="item.children")
				ul(v-if="item.children")
					//
					li(v-for="subItem in item.children", v-bind:key="subItem.name", v-if="!subItem.permission || user.permissions[subItem.permission]")
						router-link(:to="{name:subItem.name}") {{ subItem.title }}
</template>
<script>
	export default {
		name: 'SideMenu',
		methods: {
			toggleMenu(item){
				if (this.activeMenu === item){
					this.activeMenu = null;
				} else {
					this.activeMenu = item;
				}
			}
		},
		mounted(){
			let user = this.$store.state.user;
			this.user = user;

			let menuItems = [
				// {
				// 	title: 'Dashboard',
				// 	icon: 'home',
				// 	name: 'dashboard',
				// 	link: '/app/dashboard',
				// },
				{
					title: 'Offers',
					icon: 'list',
					name: 'offer-list',
					link: '/app/offers',
				},
				{
					title: 'Reports',
					icon: 'area-chart',
					children: [
						{
							title: 'Statistics',
							name: 'statistics',
							link: '/app/statistics',
						},
						// {
						// 	title: 'Events',
						// 	name: 'events',
						// 	link: '/app/events',
						// },
					],
				},
			];
			if (user.type === 'advertiser'){
				// nothing here
			} else {
				menuItems[1].children.push({
					title: 'Conversions',
					name: 'conversions',
					link: '/app/conversions',
				});
				menuItems[1].children.push({
					title: 'Rejected Installs',
					name: 'rejected',
					link: '/app/rejected',
					permission: 'rejected::VIEW',
				});
				// menuItems.push({
				// 	title: 'Settings',
				// 	icon: 'cogs',
				// 	name: 'settings',
				// 	link: '/app/settings',
				// });
				menuItems.push({
					title: 'Payments',
					icon: 'dollar',
					name: 'publisher-payments',
					link: '/app/publisher-payments',
					permission: 'payments::VIEW',
				});

				menuItems.push({
					title: 'API',
					icon: 'code',
					name: 'api',
					link: '/app/api'
				});
			}
			this.menuItems = menuItems;
		},
		data(){
			return {
				user: null,
				activeMenu: null,
				menuItems: []
			};
		}
	}
</script>
